<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card flat>
            <v-card-text>
              <div>404 Page not found</div>
              <div to="/">Go back</div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
